define("commander/pods/components/trip-table-row/component", ["exports", "@glimmer/component", "commander/config/environment", "commander/utils/utils"], function (_exports, _component, _environment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let TripTableRowComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember.computed('args.trip.contract'), (_class = (_temp = class TripTableRowComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "excludeCapacities", [1, 3]);
    }

    // @computed('args.trip.lateEventState.highlight', 'args.trip.route.dispatchEventState.highlight')
    get isLate() {
      return this.args.trip.get('lateEventState.highlight') || this.args.trip.get('route.dispatchEventState.highlight');
    }

    get higlightReadOnly() {
      return !_environment.default.APP.api.readOnly && this.args.trip.readOnly;
    }

    handleContextMenu(e) {
      if (!this.args.trip.get('readOnly')) {
        this.args.reselectRow(e.ctrlKey, this.args.trip);
      }
    }

    ignoreMainClick(e) {
      e.stopPropagation();
    }

    handleAssignVehicle(vehicle) {
      this.args.assignVehicle(this.args.trip, vehicle);
    }

    handleStartDispatch(failAction, duration, excludedVehicles) {
      this.args.startDispatch(this.args.trip.route, failAction, duration, excludedVehicles);
    }

    get contractColor() {
      return (0, _utils.stringToColor)((this.args.trip.contract || '').toLowerCase());
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "handleContextMenu", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleContextMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreMainClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreMainClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleAssignVehicle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleAssignVehicle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleStartDispatch", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleStartDispatch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contractColor", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "contractColor"), _class.prototype)), _class));
  _exports.default = TripTableRowComponent;
});