define("commander/pods/components/route-detail/component", ["exports", "commander/config/environment", "commander/utils/ui-constants"], function (_exports, _environment, _uiConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.routeStatusObserver();
    },

    vehicleActions: Ember.computed(function () {
      return {
        canAssign: false,
        canUnassign: false,
        showAssignForm: false,
        showUnassignConfirm: false
      };
    }),
    routeData: Ember.computed('route', function () {
      return {
        routes: [this.route],
        trips: this.store.peekAll('trip')
      };
    }),
    vehicle: Ember.computed('route.vehicleNumber', function () {
      return this.get('route.vehicleNumber') ? this.store.peekRecord('vehicle', this.get('route.vehicleNumber')) : null;
    }),
    routeStatusObserver: Ember.observer('route.{vehicleId,vehicleNumber,isStarted}', function () {
      const canPerformAction = !this.get('route.isStarted');
      const notReadeOnly = !this.get('route.readOnly');
      this.set('vehicleActions.canAssign', _environment.default.APP.tripModification.dispatching && canPerformAction && !this.get('route.vehicleNumber') && notReadeOnly);
      this.set('vehicleActions.canUnassign', _environment.default.APP.tripModification.dispatching && canPerformAction && !!this.get('route.vehicleNumber') && notReadeOnly);
    }),

    _filterVehicle(vehicle, filter) {
      return `${vehicle.get('name')}`.toLowerCase().includes(filter) || `${vehicle.get('number')}`.toLowerCase().includes(filter) || `${vehicle.get('phoneNumber')}`.toLowerCase().includes(filter);
    },

    showError(response, autoClear = true) {
      response.errors.forEach(i => {
        let message = '';

        try {
          message = JSON.parse(i.detail).message;
        } catch (e) {
          message = i.detail;
        }

        this.notifications.error(message, {
          cssClasses: 'commander-notification',
          autoClear,
          clearDuration: 5000
        });
      });
    },

    actions: {
      onStopClick(trip) {
        this.detailEvent(_uiConstants.DETAIL_VIEW_EVENTS.stopClick, trip);
      },

      showAssignVehicleForm() {
        this.set('vehicleActions.showAssignForm', true);
      },

      hideAssignVehicleForm() {
        this.set('vehicleActions.showAssignForm', false);
      },

      handleAssignVehicle(vehicle, forced) {
        this.route.assignVehicle({
          vehicleNumber: vehicle.get('number'),
          forced
        }).then(response => console.log(response)).catch(reason => this.showError(reason));
      },

      showUnassignVehicleConfirm() {
        this.set('vehicleActions.showUnassignConfirm', true);
      },

      hideUnassignVehicleConfirm() {
        this.set('vehicleActions.showUnassignConfirm', false);
      },

      unassignVehicle() {
        this.send('hideUnassignVehicleConfirm');
        this.route.unassignVehicle().then(response => console.log(response)).catch(reason => this.showError(reason));
      }

    }
  });

  _exports.default = _default;
});