define("commander/initializers/application", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function getDomainName() {
    return window.location.hostname;
  }

  function getSiteName() {
    const domain = getDomainName();
    const parts = domain.split('.');

    if (parts.length === 3) {
      return [parts[0], '/commander/'];
    } else if (parts.length === 4) {
      return [parts[1], '/'];
    }

    return [null, '/'];
  }

  function applyConfig(env, config) {
    function resolve(parts, root) {
      return parts.reduce((p, c) => p[c], root);
    }

    Object.keys(config).forEach(c => {
      const parts = c.split('.');
      parts.shift(); // remove the root (ENV)

      const key = parts.pop();
      const target = resolve(parts, env);
      target[key] = config[c];
    }); //env.contentSecurityPolicy['connect-src'] += ` ${env.APP.api.url}`;
  }

  function initialize()
  /* application */
  {
    const [site, rootURL] = getSiteName();

    if (site && typeof _environment.default.APP.envs[site] === 'object') {
      console.debug(`dynamically reading config for ${site}`);
      const conf = _environment.default.APP.envs[site];

      if (!conf['ENV.rootURL']) {
        conf['ENV.rootURL'] = rootURL;
      }

      if (!conf['ENV.APP.api.url']) {
        conf['ENV.APP.api.url'] = rootURL === '/' ? `https://commander-api.${site}.vinka.cloud` : `https://${site}.vinka.cloud/commander-api/`;
      }

      if (!conf['ENV.APP.api.ws']) {
        conf['ENV.APP.api.ws'] = rootURL === '/' ? `wss://commander-api.${site}.vinka.cloud` : `wss://${site}.vinka.cloud/commander-api/`;
      }

      applyConfig(_environment.default, conf);
    } else if (site) {
      const commanderdomain = getDomainName();
      const domain = commanderdomain.replace('commander', 'commander-api');
      applyConfig(_environment.default, {
        'ENV.APP.leaflet.browserLocation': true,
        'ENV.APP.api.url': `https://${domain}`,
        'ENV.APP.api.ws': `wss://${domain}`
      });
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});