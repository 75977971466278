define("commander/models/trip", ["exports", "@ember-data/model", "ember-api-actions", "commander/config/environment", "commander/utils/utils", "commander/utils/route"], function (_exports, _model, _emberApiActions, _environment, _utils, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TripModel = {
    settings: Ember.inject.service(),
    uiNotification: Ember.inject.service(),
    qpService: Ember.inject.service(),
    i18n: Ember.inject.service(),
    requestedTime: (0, _model.attr)('date'),
    routeId: (0, _model.attr)('string'),
    anchor: (0, _model.attr)('string'),
    sharing: (0, _model.attr)('string'),
    searchWindow: (0, _model.attr)(),
    promisedWindow: (0, _model.attr)(),
    distanceLimit: (0, _model.attr)(),
    timeLimit: (0, _model.attr)(),
    passenger: (0, _model.attr)(),
    start: (0, _model.attr)(),
    end: (0, _model.attr)(),
    ride: (0, _model.attr)(),
    capacity: (0, _model.attr)(),
    attributes: (0, _model.attr)(),
    planning: (0, _model.attr)(),
    additionalInfo: (0, _model.attr)('string'),
    driverInfo: (0, _model.attr)('string'),
    price: (0, _model.attr)(),
    externalId: (0, _model.attr)('string'),
    uuid: (0, _model.attr)('string'),
    contract: (0, _model.attr)('string'),
    labels: (0, _model.attr)(),
    // -- augmented
    // these shouldn't be DS.attr. to make it a normal property we would need
    // to change the way trips are created however (setting properties after
    // being pushed to store).
    stop: (0, _model.attr)(),
    path: (0, _model.attr)(),
    vehicleNumber: (0, _model.attr)('string'),
    dispatchStrategy: (0, _model.attr)(),
    readOnly: (0, _model.attr)('boolean', {
      defaultValue: _environment.default.APP.api.readOnly
    }),
    // -- misc
    typeName: 'trip',
    // set to true when the item is hovered somewhere on the page (in 'vehicle-itinerary' at the moment...)
    highlighted: false,
    // make it computed so each instance of the class has its own set of flags
    // 'notify' flag defines if there was a notification regarding the event. Set to 'true' by default to prevent notifications from appearing during init
    lateEventState: Ember.computed(function () {
      return {
        highlight: false,
        notify: false
      };
    }),
    // -- computed
    route: Ember.computed('routeId', function () {
      return this.routeId ? this.store.peekRecord('route', this.routeId) : null;
    }),
    pickStatus: Ember.computed('stop.pick.completion.status', function () {
      return this.get('stop.pick.completion.status');
    }),
    dropStatus: Ember.computed('stop.drop.completion.status', function () {
      return this.get('stop.drop.completion.status');
    }),
    pickupDifferenceMs: Ember.computed('pickupTime', 'stop.pick.eta', 'pickStatus', 'stop.pick.completion.timestamp', function () {
      return _route.default.calculateStopDelay(this.get('stop.pick'));
    }),
    dropoffDifferenceMs: Ember.computed('dropoffTime', 'stop.drop.eta', 'dropStatus', 'stop.drop.completion.timestamp', function () {
      return _route.default.calculateStopDelay(this.get('stop.drop'));
    }),
    pickupTime: Ember.computed('stop.pick.requestedTime,plannedTime,promisedTime', function () {
      return this.get('stop.pick.plannedTime') || this.get('stop.pick.promisedTime') || this.get('stop.pick.requestedTime');
    }),
    dropoffTime: Ember.computed('stop.drop.{requestedTime,plannedTime,promisedTime}', function () {
      return this.get('stop.drop.plannedTime') || this.get('stop.drop.promisedTime') || this.get('stop.drop.requestedTime');
    }),
    formattedPickAddress: Ember.computed('start', function () {
      return _route.default.formattedAddress(this.start);
    }),
    formattedDropAddress: Ember.computed('end', function () {
      return _route.default.formattedAddress(this.end);
    }),
    lateEventStateObserver: Ember.observer('{pickupDifferenceMs,dropoffDifferenceMs}', 'settings.events.tripLate.{highlight,notify}', function () {
      // TODO: All the required calculations are already performed for 'pickupDifferenceMs', 'dropoffDifferenceMs'. Maybe it is possible to avoid the 'routeUtils.secondsLate' call?..
      const pick = this.get('stop.pick');
      const drop = this.get('stop.drop');
      const isTripLate = pick && drop ? _route.default.secondsLate(Ember.A([pick, drop])) > _route.default.TRIP_LATE_THRESHOLD : false;
      this.set('lateEventState.highlight', isTripLate && this.get('settings.events.tripLate.highlight'));

      if (_utils.default.shouldTriggerEventNotify(this, isTripLate, this.get('settings.events.tripLate.notify'), 'lateEventState.notify')) {
        //this.get('uiNotification').playAudio();
        this.uiNotification.showDesktopNotification(this.i18n.t('notification.tripLate', {
          tripId: this.id
        }), () => {
          this.qpService.setQP('trip', this.id);
          window.focus();
        });
      }
    }),
    isAsap: Ember.computed('labels.[]', function () {
      return this.labels && this.labels.includes('asap');
    }),
    isSOS: Ember.computed('labels.[]', function () {
      return this.labels && this.labels.includes('sos');
    }),

    init() {
      this._super(...arguments); // trigger the observer to set the initial states


      this.lateEventStateObserver();
    },

    isNoShow(pickStatus) {
      return pickStatus === _route.default.STOP_STATES.noShow;
    },

    isPassengerOnBoard(pickStatus, dropStatus) {
      return pickStatus === _route.default.STOP_STATES.performed && dropStatus !== _route.default.STOP_STATES.performed && dropStatus !== _route.default.STOP_STATES.noShow;
    },

    isStarted(pickStatus, dropStatus) {
      pickStatus = pickStatus || this.pickStatus;
      dropStatus = dropStatus || this.dropStatus;
      return pickStatus && pickStatus !== _route.default.STOP_STATES.planned || dropStatus && dropStatus !== _route.default.STOP_STATES.planned;
    },

    isFinished(dropStatus) {
      return dropStatus === _route.default.STOP_STATES.performed;
    },

    isArrivingPickup(pickStatus, dropStatus) {
      return pickStatus === _route.default.STOP_STATES.arrived && dropStatus === _route.default.STOP_STATES.planned;
    },

    tripStatus: Ember.computed('pickStatus', 'dropStatus', function () {
      const pickStatus = this.pickStatus;
      const dropStatus = this.dropStatus;
      let status = _route.default.TRIP_STATES.notStarted;

      if (this.isNoShow(pickStatus)) {
        status = _route.default.TRIP_STATES.noShow;
      } else if (this.isFinished(dropStatus)) {
        status = _route.default.TRIP_STATES.finished;
      } else if (this.isPassengerOnBoard(pickStatus, dropStatus)) {
        status = _route.default.TRIP_STATES.onBoard;
      } else if (this.isArrivingPickup(pickStatus, dropStatus)) {
        status = _route.default.TRIP_STATES.arrivingPickup;
      }

      return status;
    }),
    tripSortStatus: Ember.computed('tripStatus', 'route.dispatchStatus', function () {
      const status = this.tripStatus;
      const dispatchStatus = this.get('route.dispatchStatus');
      return status === _route.default.TRIP_STATES.notStarted && _route.default.DISPATCH_STATUSES.hasOwnProperty(dispatchStatus) ? _route.default.DISPATCH_STATUSES[dispatchStatus].sort : status;
    }),
    dispatchStrategyIncludedVehicles: Ember.computed('dispatchStrategy.params.includedVehicles.[]', function () {
      return ((this.dispatchStrategy || {}).params || {}).includedVehicles || [];
    }),
    // ember-api-actions start here
    schedule: (0, _emberApiActions.memberAction)({
      path: 'schedule',
      type: 'put'
    }),
    unschedule: (0, _emberApiActions.memberAction)({
      path: 'unschedule',
      type: 'delete'
    }),
    delete: (0, _emberApiActions.memberAction)({
      path: 'delete',
      type: 'delete'
    }),
    createRoute: (0, _emberApiActions.collectionAction)({
      path: 'create-route',
      type: 'post'
    }),
    history: (0, _emberApiActions.memberAction)({
      path: 'history',
      type: 'get'
    })
  };

  var _default = _model.default.extend(TripModel);

  _exports.default = _default;
});