define("commander/pods/components/offer-candidate-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    displayWindow: Ember.inject.service(),
    classNames: ['vinka-flex-column vinka-max-50vh'],
    isFetchingOffers: false,
    candidateOffers: [],
    actions: {
      fetchOffers() {
        if (!this.isFetchingOffers) {
          const timeFrame = this.displayWindow.getWindow();
          const searchWindow = {
            start: timeFrame.start.toISOString(),
            end: timeFrame.end.toISOString()
          };
          this.set('isFetchingOffers', true);
          this.entity.getCandidateOffers(searchWindow).then(data => this.set('candidateOffers', data.sort((a, b) => a.startTime.localeCompare(b.startTime)))).catch(reason => console.log(reason)).finally(() => this.set('isFetchingOffers', false));
        }
      }

    }
  });

  _exports.default = _default;
});